/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

:root {

	// COLOURS
	// ---------------

	--color1:               #231f20; // black
	--color2:               #6bb5b9; // blue
	--color3:               #adacab; // grey
	--color4:               #5F5E5D; // grey, footer

	// TYPOGRAPHY
	// ---------------

	--text-color:            #231f20;
	--text-base-size:        16px;
	--base-line-height:      1.612;
	--base-type-spacing:     1.875rem;

	--font-family-primary:   "Roboto Mono", Helvetica, Arial, sans-serif;
	--font-family-secondary: "Amatic SC", handwriting;
	--font-family-headings:  var(--font-family-secondary);
	
	--headings-color:        var(--text-color);
	--hr-color:              #dcdbdc;

}